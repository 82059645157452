import { Image } from 'react-bootstrap';
import { link } from '../../../../global/types/strapi-api/link';
import joinRemind from '../../assets/join-remind.png';
import './rightColumn.css';

interface Props {
  twitchStreamers?: link[];
  discordLink: string;
}

export const RightColumn = (props: Props) => {
  return (
    <>
      {props.twitchStreamers.length > 0 ? (
        <>
          <h3>Twitch Streamers:</h3>
          {props.twitchStreamers.map((streamer, index) => (
            <a href={streamer.Link} key={index} target="_blank" rel="noreferrer">
              {streamer.Text}
            </a>
          ))}
          <hr />
        </>
      ) : undefined}

      <h3>Join the Discord!</h3>
      <a href={`${props.discordLink}`} className="textWrap">
        {props.discordLink}
      </a>

      <hr />

      <h3>Join the Remind!</h3>
      <p id="remindText" className="textWrap">
        Stay up to date on the latest updates & news
      </p>
      <Image id="remindImage" src={joinRemind} className={'d-lg-block'} />

      <hr />
    </>
  );
};
