import { useEffect, useState } from 'react';
import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { HeaderNav } from '../../global/components/HeaderNav';
import { Loading } from '../../global/components/Loading';
import '../../global/css/plugins.css';
import { plugin } from '../../global/types/strapi-api/plugin';
import { config } from '../../global/utils/Config';
import { Utils } from '../../global/utils/Utils';

interface State {
  description: string;
  plugins: plugin[];
}

interface pluginsResponse {
  Description: string;
  Plugins: plugin[];
}

export const Plugins = () => {
  const initState: State = {
    description: undefined,
    plugins: undefined
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(
    () => {
      Utils.getCMS('Plugins', (response: pluginsResponse, err?: Error) => {
        if (err) {
          setError(true);
          console.error(err);
          return;
        }
        setState({
          description: response.Description,
          plugins: response.Plugins
        });
        setLoading(false);
      });
    }, // eslint-disable-next-line
    []
  );

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
      <HeaderNav activePage="plugins" />
      <Container style={{ marginTop: '50px' }}>
        <Row>
          <Col>
            <h1 style={{ margin: '0 auto' }}>Plugins</h1>
            <hr />

            <p style={{ marginBottom: '30px' }}>{state.description}</p>

            {state.plugins
              ? state.plugins.map((plugin) => (
                  <>
                    <Image
                      src={`${config.strapiURL}${plugin.Icon.url}`}
                      className="pluginIcon"
                      onClick={() => window.open(plugin.Plugin_Link)}
                      thumbnail
                    />
                    <h3 className="pluginName">{plugin.Plugin_Name}</h3>
                    <hr />

                    <h4 style={{ marginBottom: '10px' }}>Description:</h4>
                    <ReactMarkdown children={plugin.Description} />
                    <br />

                    {plugin.Commands.length > 0 ? <h5>Commands:</h5> : ''}
                    <ListGroup className="commandListItem" style={{ borderRadius: '16px', marginBottom: '50px' }}>
                      {plugin.Commands.map((command) => (
                        <>
                          <ListGroup.Item>
                            <h5 className="syntax">{command.Syntax}</h5>
                            <ReactMarkdown children={command.Description} />
                          </ListGroup.Item>
                        </>
                      ))}
                    </ListGroup>
                  </>
                ))
              : ''}
          </Col>
        </Row>
      </Container>
    </>
  );
};
