import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { announcement } from '../../../../global/types/strapi-api/homepage';
import './announcements.css';

interface Props {
  posts: announcement[];
}

export const Announcements = (props: Props) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <>
      {props.posts
        .filter((post, index) => readMore || index < 3)
        .map((post, index) => (
          <Container className="announcement" key={index}>
            <h2>{post.Header}</h2>
            <h5>{post.Date}</h5>
            <ReactMarkdown children={post.Content} />
          </Container>
        ))}
      <Row>
        {readMore || props.posts.length <= 3 ? undefined : (
          <p
            style={{ margin: 'auto', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setReadMore(true)}
          >
            Read More
          </p>
        )}
      </Row>
    </>
  );
};
