import { cmsPhoto, strapiPhoto } from '../types/strapi-api/strapi-photo';
import { config } from './Config';

type photoSizes = 'full' | 'large' | 'medium' | 'small' | 'thumbnail';

class utils {
  public getCMS = (endpoint: string, callback: Function) => {
    fetch(`${config.strapiURL}/${endpoint}`, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((response) => callback(response))
      .catch((err) => {
        console.error('CMS Fetch error: ', err);
        callback({}, err);
      });
  };

  public getMCUsername = async (guid: string): Promise<string> => {
    const names: any = await fetch(`https://api.mojang.com/user/profiles/${guid}/names`, { method: 'GET' });
    return names.json()[0].name;
  };

  public parseCMSPhoto = (photo: strapiPhoto): cmsPhoto => {
    return {
      full: getCMSPhotoURL(photo, 'full'),
      large: getCMSPhotoURL(photo, 'large'),
      medium: getCMSPhotoURL(photo, 'medium'),
      small: getCMSPhotoURL(photo, 'small'),
      thumbnail: getCMSPhotoURL(photo, 'thumbnail')
    };
  };

  public urlSpaceConvert = (to: 'space' | '%20', url: string): string => {
    if (to === 'space') return url.replace('%20', ' ');
    if (to === '%20') return decodeURI(url);
  };

  public roundFileSize = (size: number): string => {
    if (size < 1000) return `${Math.round(size)} KB`;
    if (size < 100000) return `${Math.round(size / 1000)} MB`;
    if (size < 10000000) return `${Math.round(size / 100000)} GB`;
  };
}

const getCMSPhotoURL = (photo: strapiPhoto, size: photoSizes): string => {
  if (size === 'full') return `${config.strapiURL}${photo.url}`;
  return `${config.strapiURL}${photo.formats[size].url}`;
};

export const Utils = new utils();
