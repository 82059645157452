import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './global/css/index.css';
import { Archive } from './pages/Archive/Archive';
import { External } from './pages/External/External';
import { Home } from './pages/Home/Home';
import { Members } from './pages/Members';
import { Plugins } from './pages/Plugins/Plugins';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/plugins" element={<Plugins />} />
          <Route path="/members" element={<Members />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/external" element={<External />} />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
