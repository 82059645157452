import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { headerNav, navLink } from '../types/strapi-api/header-nav';
import { Utils } from '../utils/Utils';
import { Loading } from './Loading';

interface Props {
  activePage: string;
}

interface State {
  yakistanSeason: string;
  internalLinks: navLink[];
  externalLinks: navLink[];
}

export const HeaderNav = (props: Props) => {
  const initState: State = {
    yakistanSeason: undefined,
    externalLinks: undefined,
    internalLinks: undefined
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    Utils.getCMS('header-nav', (response: headerNav, err?: Error) => {
      if (err) {
        setError(true);
        console.error(err);
        return;
      }
      setState({
        yakistanSeason: response.Yakistan_Season,
        internalLinks: response.Nav_Links?.filter((link) => link.Internal),
        externalLinks: response.Nav_Links?.filter((link) => !link.Internal)
      });
      setLoading(false);
    });
  }, []);

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/home">
            <img
              alt=""
              src="./favicon_io/favicon.ico"
              width="30"
              height="30"
              className="d-inline-block align-top"
              style={{ marginRight: '18px' }}
            />
            Yakistan: Season {state.yakistanSeason}
          </Navbar.Brand>
          <Nav className="justify-content-end" activeKey={`/${props.activePage}`}>
            {/* Internal Links */}
            <Nav.Item>
              <Nav.Link href="/home">Home</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/plugins">Plugins</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/members">Members</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/archive">Archive</Nav.Link>
            </Nav.Item>

            {
              /* CMS Internal Links */
              state.internalLinks?.map((link, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    href={`/external?displayName=${Utils.urlSpaceConvert('%20', link.Display_Name)}&url=${link.Link}`}
                  >
                    {link.Display_Name}
                  </Nav.Link>
                </Nav.Item>
              ))
            }

            {
              /* CMS External Links */
              state.externalLinks?.map((link, index) => (
                <Nav.Item key={index}>
                  <Nav.Link href={`${link.Link}`} target="_blank">
                    {link.Display_Name}
                  </Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
