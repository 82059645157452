import { useEffect, useState } from 'react';
import { Loading } from '../../../../global/components/Loading';
import { PlayerHead } from '../../../../global/components/PlayerHead';
import { mcAPIResponse, mcPlayer } from '../../../../global/types/mc-api';
import './leftColumn.css';

interface Props {
  serverVersion: string;
}

interface State {
  status: string;
  online: boolean;
  motd: string;
  players: {
    max: number;
    now: number;
    list: mcPlayer[];
  };
}

export const LeftColumn = (props: Props) => {
  const initState: State = {
    status: undefined,
    online: undefined,
    motd: undefined,
    players: {
      max: undefined,
      now: undefined,
      list: undefined
    }
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch('https://mcapi.us/server/status?ip=mc.thesamdickey.com', {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((response: mcAPIResponse) => {
        setState({
          status: response.status,
          online: response.online,
          motd: response.motd,
          players: {
            max: response.players.max,
            now: response.players.now,
            list: response.players.sample
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error('serverStatus Fetch error: ', err);
        setError(true);
      });
  }, []);

  return (
    <>
      <h3>Server IP:</h3>
      <h5 style={{ color: '#0fe300' }}>
        <b id="serverAddress">mc.thesamdickey.com</b>
      </h5>
      <p>version {props.serverVersion}</p>

      <hr />

      <h3>Server Status:</h3>
      {loading || error ? (
        <Loading error={error} />
      ) : state.online ? (
        <h4 className="serverStatus serverOnline">Server is Online!</h4>
      ) : (
        <h4 className="serverStatus serverOffline">Server is down :(</h4>
      )}

      <hr />

      {loading || error ? (
        <Loading error={error} />
      ) : (
        <>
          <h3>
            {state.players.now === 0
              ? `Ain't nobody online!`
              : state.players.now === 1
              ? `${state.players.now} player online`
              : `${state.players.now} players online`}
          </h3>

          {state.players.list.map((player, index) => (
            <PlayerHead
              uuid={player.id}
              username={player.name}
              tooltip={true}
              style={{ width: '55px', padding: '5px', cursor: 'pointer' }}
              key={index}
            />
          ))}
          <hr />
        </>
      )}
    </>
  );
};
