import { useEffect, useState } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loading } from './Loading';

interface Props {
  uuid: string;
  style?: any;
  tooltip?: boolean;
  username?: string;
}

export const PlayerHead = (props: Props) => {
  const [src, setSrc] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getHead = async (nohelm: boolean) => {
    return await fetch(`https://mc-heads.net/avatar/${props.uuid}/100${nohelm ? '/nohelm' : ''}`, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      )
      .catch((err) => {
        setError(true);
        console.error('getMCHead Fetch error: ', err);
      });
  };

  const blackBase64 =
    'iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAElEQVR4nO3RQQ0AIBDAsAP/nuGNAvZoFSzZmpkzZOzfAbwMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEk5gLcywHHnWLL6AAAAABJRU5ErkJggg==';
  const whiteBase64 =
    'iVBORw0KGgoAAAANSUhEUgAAAGQAAABkBAMAAACCzIhnAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAADUExURf///6fEG8gAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAcSURBVFjD7cEBDQAAAMKg909tDwcEAAAAAByoARPsAAFwJuooAAAAAElFTkSuQmCC';

  useEffect(
    () => {
      getHead(true).then((img: any) => {
        const base64 = img.split(',')[1];
        if (base64 !== blackBase64 && base64 !== whiteBase64) {
          setSrc(img);
          setLoading(false);
        } else {
          getHead(false).then((helm: any) => {
            setSrc(helm);
            setLoading(false);
          });
        }
      });
    },
    // eslint-disable-next-line
    []
  );

  const combinedStyle = {
    ...props.style,
    imageRendering: 'pixelated'
  };

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      {props.tooltip ? (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${props.username}`}>{`${props.username}`}</Tooltip>}
        >
          <Image src={`${src}`} style={combinedStyle} />
        </OverlayTrigger>
      ) : (
        <Image src={`${src}`} style={combinedStyle} />
      )}
    </>
  );
};
