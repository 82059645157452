import { useEffect, useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Footer } from '../global/components/Footer';
import { HeaderNav } from '../global/components/HeaderNav';
import { Loading } from '../global/components/Loading';
import { PlayerHead } from '../global/components/PlayerHead';
import { member, members } from '../global/types/strapi-api/members';
import { cmsPhoto } from '../global/types/strapi-api/strapi-photo';
import { Utils } from '../global/utils/Utils';

interface State {
  members: [member[]];
  titleImage: cmsPhoto;
}

export const Members = () => {
  const initState: State = {
    members: undefined,
    titleImage: undefined
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    Utils.getCMS('Members', (response: members, err?: Error) => {
      if (err) {
        setError(true);
        console.error(err);
        return;
      }
      setState({
        members: sortMembers(response.Member),
        titleImage: Utils.parseCMSPhoto(response.Title_Image)
      });
      setLoading(false);
    });
  }, []);

  const sortMembers = (members: member[]): [member[]] => {
    const sortedMembers: [member[]] = [[]];
    members
      .sort((a, b) => a.Season_Joined - b.Season_Joined)
      .forEach((member, index, members) => {
        if ((index > 1 && member.Season_Joined > members[index - 1].Season_Joined) || index === 0)
          sortedMembers.push([]);
        sortedMembers[member.Season_Joined - 1].push(member);
      });
    return sortedMembers;
  };

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      <HeaderNav activePage="members" />

      <Container id="bodyContainer">
        <Row>
          <Image
            src={state.titleImage.full}
            style={{ width: '60%', margin: 'auto', marginBottom: '-1rem', marginTop: '1rem' }}
          />
        </Row>

        {state.members.map((members, index) => (
          <>
            <h2 style={{ fontWeight: 'bold', marginTop: '50px' }}>Season {index + 1}</h2>
            <hr style={{ backgroundColor: '#ababab', marginBottom: '-1rem' }} />
            <Row>
              {members.map((member) => (
                <>
                  <Col sm={3} style={{ paddingTop: '50px', borderRadius: '.25rem' }} key={index}>
                    <Card
                      style={{
                        width: '250px',
                        marginLeft: '15px',
                        marginRight: '15px',
                        borderRadius: '8px',
                        boxShadow: 'rgba(0, 0, 0, 0.6) 2px 2px 6px 0px',
                        border: '0px'
                      }}
                    >
                      <PlayerHead uuid={member.UUID} />
                      <Card.Body>
                        <Card.Title>{member.Username}</Card.Title>
                        {member.Additional_Text ? (
                          <Card.Text>
                            <ReactMarkdown children={member.Additional_Text} />
                          </Card.Text>
                        ) : undefined}
                        <Card.Text>Member Since: S{member.Season_Joined}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ))}
            </Row>
          </>
        ))}
      </Container>

      <Footer />
    </>
  );
};
