import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { HeaderNav } from '../../global/components/HeaderNav';
import { Loading } from '../../global/components/Loading';
import { archive, embeddedVideo, fileDownload } from '../../global/types/strapi-api/archive';
import { config } from '../../global/utils/Config';
import { Utils } from '../../global/utils/Utils';
import './css/archive.css';

interface State {
  embeddedVideos?: embeddedVideo[];
  fileDownloads?: fileDownload[];
}

export const Archive = () => {
  const initState: State = {
    embeddedVideos: undefined,
    fileDownloads: undefined
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(
    () => {
      Utils.getCMS('Archive', (response: archive, err?: Error) => {
        if (err) {
          setError(true);
          console.error(err);
          return;
        }
        setState({
          embeddedVideos: response?.Embedded_Videos,
          fileDownloads: response?.File_Downloads
        });
        setLoading(false);
      });
    }, // eslint-disable-next-line
    []
  );

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
      <HeaderNav activePage="archive" />
      <Container style={{ marginTop: '50px' }}>
        <Row>
          <Col md={6}>
            <h1 style={{ margin: '0 auto' }}>File Downloads</h1>
            <hr />
            {state.fileDownloads.map((file, index) => (
              <>
                <a
                  href={file?.File_Override_URL ? `${file.File_Override_URL}` : `${config.strapiURL}${file.File.url}`}
                  className={`archiveFileDownload archiveFileDownload${index % 2}`}
                >
                  <span className="material-icons fileIcon">{file.Icon}</span>
                  <span className="fileTitle"> {`${file.Title}${file?.File?.ext ?? ''}`}</span>
                  <span className="fileSize">{Utils.roundFileSize(file.File_Override_Size ?? file.File.size)}</span>
                </a>
              </>
            ))}
          </Col>

          <Col md={6}>
            <h1 style={{ margin: '0 auto' }}>Videos</h1>
            <hr />
            {state.embeddedVideos.map((video) => (
              <>
                <h4 style={{ fontFamily: "'Roboto', sans-serif", fontWeight: 300, marginTop: '20px' }}>
                  {video.Title}
                </h4>
                <ReactPlayer url={video.URL} widgth="250px" controls={true} />
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};
