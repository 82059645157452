import React, { useEffect, useState } from 'react';
import { HeaderNav } from '../../global/components/HeaderNav';
import { Utils } from '../../global/utils/Utils';
import './css/external.css';

interface Props {}

// Using this has problems with css, fix before usage!!

interface State {
  url: string;
  displayName: string;
}

export const External = (props: Props) => {
  const initState: State = {
    url: undefined,
    displayName: undefined
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setState({
      url: queryParams.get('url'),
      displayName: queryParams.get('displayName')
    });
  }, []);

  return (
    <>
      <HeaderNav
        activePage={`external?displayName=${Utils.urlSpaceConvert('%20', state.displayName)}&url=${state.url}`}
      />
      <iframe src={state.url} title={state.displayName} id="externalIframe"></iframe>
    </>
  );
};
