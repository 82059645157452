import { Container } from 'react-bootstrap';

export const Footer = () => {
  return (
    <div
      className="footer-copyright text-center py-3"
      style={{
        backgroundColor: '#343A40',
        color: 'white',
        paddingTop: '0px',
        marginTop: '100px'
      }}
    >
      <Container fluid>
        &copy; {new Date().getFullYear()} Copyright{' '}
        <a href="https://thesamdickey.com" target="_blank" rel="noreferrer" style={{ color: 'rgb(255 122 0)' }}>
          {' '}
          Sam Dickey{' '}
        </a>
      </Container>
    </div>
  );
};
