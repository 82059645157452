import { Container, Spinner } from 'react-bootstrap';
import '../css/loading.css';

interface Props {
  error?: boolean;
}

export const Loading = (props: Props) => {
  return (
    <>
      <Container id={'Loading'}>
        {props.error ? (
          <>
            <h1>
              <span style={{ color: 'red', fontWeight: 'bold' }}>Error!</span> The web server is not responding :(
            </h1>
            <p>(sorry about that)</p>

            <span>
              Check the Minecraft server status{' '}
              <a href="https://mcsrvstat.us/server/mc.thesamdickey.com" target="_blank" rel="noreferrer">
                here
              </a>
            </span>
          </>
        ) : (
          <Spinner animation="border" role="status"></Spinner>
        )}
      </Container>
    </>
  );
};
