import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Footer } from '../../global/components/Footer';
import { HeaderNav } from '../../global/components/HeaderNav';
import { Loading } from '../../global/components/Loading';
import { announcement, homepage } from '../../global/types/strapi-api/homepage';
import { link } from '../../global/types/strapi-api/link';
import { cmsPhoto } from '../../global/types/strapi-api/strapi-photo';
import { Utils } from '../../global/utils/Utils';
import { Announcements } from './components/Announcements/Announcements';
import { LeftColumn } from './components/LeftColumn/LeftColumn';
import { RightColumn } from './components/RightColumn/RightColumn';
import './home.css';

interface HomePage {
  frontCover: cmsPhoto;
  serverVersion: string;
  discordLink: string;
  twitchStreamers: link[];
  announcements: announcement[];
}

export const Home = () => {
  const initHomePage: HomePage = {
    frontCover: undefined,
    serverVersion: undefined,
    discordLink: undefined,
    twitchStreamers: undefined,
    announcements: undefined
  };

  const [homepage, setHomepage] = useState(initHomePage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    Utils.getCMS('Index', (response: homepage, err?: Error) => {
      if (err) {
        setError(true);
        console.error(err);
        return;
      }
      setHomepage({
        frontCover: Utils.parseCMSPhoto(response.Front_Cover),
        serverVersion: response.Server_Version,
        discordLink: response.Discord_Link,
        twitchStreamers: response.Twitch_Streamers,
        announcements: response.Announcement
      });
      setLoading(false);
    });
  }, []);

  return loading || error ? (
    <Loading error={error} />
  ) : (
    <>
      <Image src={homepage.frontCover.full} id="headerPhoto" />

      <HeaderNav activePage="home" />

      <Container id="bodyContainer">
        <Row>
          <Col md={2}>
            <LeftColumn serverVersion={homepage.serverVersion} />
          </Col>
          <Col md={8} id="centerColumn">
            <Announcements posts={homepage.announcements} />
          </Col>
          <Col md={2} id="rightColumn">
            <RightColumn discordLink={homepage.discordLink} twitchStreamers={homepage.twitchStreamers} />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};
